import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  scorecardData: [],
  scorecardStatus: "idle",
  currentSector: [],
  currentMarket: "All",
  isAllSector: true,
  isAllCategory: true,
  currentCategory: [],
  currentCategorySector: [],
};

const createReducer = (key) => (state, action) => {
  state[key] = action.payload;
};

export const fetchScoreCardData = createAsyncThunk(
  "scorecard/fetchScorecard",
  async (postData) => {
    const idToken = localStorage.getItem("token");
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/scorecard/kpis`,
      postData,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
    console.log("Scorecard Response :::", response);
    return response?.data;
  }
);

const ScorecardSlice = createSlice({
  name: "scorecard",
  initialState,
  reducers: {
    changeSector: createReducer("currentSector"),
    changeCategory:createReducer("currentCategory"),
    changeCategorySector: createReducer("currentCategorySector"),
    changeMarket: createReducer("currentMarket"),
    // changeDriver: createReducer("currentDriver"),
    changeIsAllSector: createReducer("isAllSector"),
    changeIsAllCategory: createReducer("isAllCategory"),
    // changeIsAllLever: createReducer("isAllLever"),
    // changeIsAllDriver: createReducer("isAllDriver"),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchScoreCardData.pending, (state) => {
        state.scorecardStatus = "loading";
        state.scorecardData = [];
      })
      .addCase(fetchScoreCardData.rejected, (state) => {
        state.scorecardStatus = "failed";
        state.scorecardData = [];
      })
      .addCase(fetchScoreCardData.fulfilled, (state, action) => {
        state.scorecardStatus = "succeeded";
        state.scorecardData = action.payload.data;
      });
  },
});

export const { changeSector, changeCategory, changeMarket, changeIsAllSector, changeIsAllCategory, changeCategorySector } =
  ScorecardSlice.actions;

export default ScorecardSlice.reducer;
