import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  ListItemText,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";

import {
  changeSelectedClassification,
  changeSelectedComplexity,
  changeSelectedFinancialOwner,
  changeSelectedGPCurrentYearMax,
  changeSelectedGPCurrentYearMin,
  changeSelectedGPNextYearMax,
  changeSelectedGPNextYearMin,
  changeSelectedGPYearAfterNextMax,
  changeSelectedGPYearAfterNextMin,
  changeSelectedInitiative,
  changeSelectedIsValid,
  changeSelectedOwner,
  changeSelectedSecondOwner,
  changeSelectedStatus,
  changeSelectedTags,
  fetchInitiativeFinancialData,
  fetchInitiativeOverviewData,
} from "../../../redux/reducers/InitiativeSlice";
import { CONSTANTS } from "../../../data/constants";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

const MoreFilterComponent = ({ name, icon }) => {
  const [searchOwner, setSearchOwner] = useState("");
  const [searchSecondOwner, setSearchSecondOwner] = useState("");
  const [searchFinancialOwner, setSearchFinancialOwner] = useState("");
  const [searchInitiative, setSearchInitiative] = useState("");

  const [selectAllInitiative, setSelectAllInitiative] = useState(false);
  const [selectAllOwner, setSelectAllOwner] = useState(false);
  const [selectAllSecondOwner, setSelectAllSecondOwner] = useState(false);
  const [selectAllFinancialOwner, setSelectAllFinancialOwner] = useState(false);
  const [selectAllStatus, setSelectAllStatus] = useState(false);
  const currentYear = CONSTANTS?.currentYear;
  const dispatch = useDispatch();
  const {
    selectedInitiative,
    selectedOwner,
    selectedSecondOwner,
    selectedLog,
    selectedFinancialOwner,
    selectedIsValid,
    selectedComplexity,
    selectedClassification,
    selectedTags,
    selectedStatus,
    selectedGPCurrentYearMin,
    selectedGPCurrentYearMax,
    selectedGPNextYearMin,
    selectedGPNextYearMax,
    selectedGPYearAfterNextMin,
    selectedGPYearAfterNextMax,
    isAllDriver,
    isAllLever,
    isAllSector,
    currentDriver,
    currentLever,
    currentSector,
    currentTab,
    overviewPaginationModel,
  } = useSelector((state) => state.InitiativeSlice);
  const [moreFilterData, setMoreFilterData] = useState([]);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const idToken = localStorage.getItem("token");
    if (idToken) {
      setToken(idToken);
    }
  }, []);

  useEffect(() => {
    const fetchMoreFilter = async () => {
      let filterObj = {
        sector: !isAllSector ? currentSector.length && currentSector : [],
        lever: !isAllLever ? currentLever.length && currentLever : [],
        driver: !isAllDriver ? currentDriver.length && currentDriver : [],
      };
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/filters/more`,
          { filters: filterObj },
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response.data.data;
        setMoreFilterData(data);
      } catch (error) {
        console.error("Error fetching more filters:", error);
      }
    };
    if (token) {
      fetchMoreFilter();
    }
  }, [
    token,
    isAllDriver,
    isAllLever,
    isAllSector,
    currentDriver,
    currentLever,
    currentSector,
  ]);

  const handleSelectAll = (
    filterType,
    allOptions,
    selectedOptions,
    setSelectedAll,
    dispatchAction
  ) => {
    if (selectedOptions.length < allOptions.length) {
      setSelectedAll(true);
      dispatch(dispatchAction(allOptions));
    } else {
      setSelectedAll(false);
      dispatch(dispatchAction([]));
    }
  };

  useEffect(() => {
    if (selectedInitiative?.length === moreFilterData?.initiative?.length) {
      setSelectAllInitiative(true);
    } else {
      setSelectAllInitiative(false);
    }
  }, [selectedInitiative, moreFilterData?.initiative]);

  useEffect(() => {
    if (selectedOwner?.length === moreFilterData?.owner?.length) {
      setSelectAllOwner(true);
    } else {
      setSelectAllOwner(false);
    }
  }, [selectedOwner, moreFilterData?.owner]);

  useEffect(() => {
    if (selectedSecondOwner?.length === moreFilterData?.delegate?.length) {
      setSelectAllSecondOwner(true);
    } else {
      setSelectAllSecondOwner(false);
    }
  }, [selectedSecondOwner, moreFilterData?.delegate]);

  useEffect(() => {
    if (
      selectedFinancialOwner?.length === moreFilterData?.financialOwner?.length
    ) {
      setSelectAllFinancialOwner(true);
    } else {
      setSelectAllFinancialOwner(false);
    }
  }, [selectedFinancialOwner, moreFilterData?.financialOwner]);

  useEffect(() => {
    if (selectedStatus?.length === moreFilterData?.status?.length) {
      setSelectAllStatus(true);
    } else {
      setSelectAllStatus(false);
    }
  }, [selectedStatus, moreFilterData?.status]);

  useEffect(() => {
    console.log(
      "More Filter Data from Redux ::",
      selectedStatus,
      selectedGPCurrentYearMin,
      selectedIsValid
    );
  }, [
    dispatch,
    selectedInitiative,
    selectedOwner,
    selectedSecondOwner,
    selectedFinancialOwner,
    selectedIsValid,
    selectedComplexity,
    selectedClassification,
    selectedTags,
    selectedStatus,
    selectedGPCurrentYearMin,
    selectedGPCurrentYearMax,
    selectedGPNextYearMin,
    selectedGPNextYearMax,
    selectedGPYearAfterNextMin,
    selectedGPYearAfterNextMax,
  ]);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClearInititative = () => {
    dispatch(changeSelectedInitiative([]));
  };

  const handleClearOwner = () => {
    dispatch(changeSelectedOwner([]));
  };

  const handleClearSecondOwner = () => {
    dispatch(changeSelectedSecondOwner([]));
  };

  const handleClearFinancialOwner = () => {
    dispatch(changeSelectedFinancialOwner([]));
  };

  const handleClearStatus = () => {
    dispatch(changeSelectedStatus([]));
  };

  const handleClear = () => {
    dispatch(changeSelectedGPCurrentYearMin(0));
    dispatch(changeSelectedGPCurrentYearMax(0));
    dispatch(changeSelectedGPNextYearMin(0));
    dispatch(changeSelectedGPNextYearMax(0));
    dispatch(changeSelectedGPYearAfterNextMin(0));
    dispatch(changeSelectedGPYearAfterNextMax(0));
    dispatch(changeSelectedInitiative([]));
    dispatch(changeSelectedOwner([]));
    dispatch(changeSelectedSecondOwner([]));
    dispatch(changeSelectedFinancialOwner([]));
    dispatch(changeSelectedTags([]));
    dispatch(changeSelectedStatus([]));
    dispatch(changeSelectedIsValid("All"));
    dispatch(changeSelectedComplexity(""));
    dispatch(changeSelectedClassification(""));
  };

  const handleApply = () => {
    let filterObj = {
      sector: !isAllSector ? currentSector.length && currentSector : [],
      lever: !isAllLever ? currentLever.length && currentLever : [],
      driver: !isAllDriver ? currentDriver.length && currentDriver : [],
      [`gp_${currentYear}_min`]:
        selectedGPCurrentYearMin > 0 ? selectedGPCurrentYearMin : null,
      [`gp_${currentYear}_max`]:
        selectedGPCurrentYearMax > 0 ? selectedGPCurrentYearMax : null,
      [`gp_${currentYear + 1}_min`]:
        selectedGPNextYearMin > 0 ? selectedGPNextYearMin : null,
      [`gp_${currentYear + 1}_max`]:
        selectedGPNextYearMax > 0 ? selectedGPNextYearMax : null,
      [`gp_${currentYear + 2}_min`]:
        selectedGPYearAfterNextMin > 0 ? selectedGPYearAfterNextMin : null,
      [`gp_${currentYear + 2}_max`]:
        selectedGPYearAfterNextMax > 0 ? selectedGPYearAfterNextMax : null,
      initiative: selectedInitiative,
      owner: selectedOwner,
      delegate: selectedSecondOwner,
      financial_owner: selectedFinancialOwner,
      status: selectedStatus,
      complexity: selectedComplexity,
      inv_type: selectedLog === "All" ? ["OPPORTUNITY", "INITIATIVE"] : [selectedLog?.toUpperCase()],
      is_valid: selectedIsValid === "All" ? ["Yes", "No"] : [selectedIsValid],
    };

    let filterData = {
      pageNo: 1,
      limit: overviewPaginationModel?.pageSize,
      sortBy: `gp_${currentYear}`,
      direction: "desc",
      filters: filterObj,
    };
    if (currentTab === "initiative-overview") {
      dispatch(fetchInitiativeOverviewData(filterData));
    }
    if (currentTab === "imm-financial") {
      dispatch(fetchInitiativeFinancialData(filterData));
    }
    handleClose();
  };

  const open = Boolean(anchorEl);

  const handleInitCheckbox = (option) => {
    let newSelectedOptions;
    if (selectedInitiative?.includes(option)) {
      newSelectedOptions = selectedInitiative.filter((item) => item !== option);
      dispatch(changeSelectedInitiative(newSelectedOptions));
    } else {
      newSelectedOptions = [...selectedInitiative, option];
      dispatch(changeSelectedInitiative(newSelectedOptions));
    }
  };

  const handleOwnerCheckbox = (option) => {
    let newSelectedOptions;
    if (selectedOwner?.includes(option)) {
      newSelectedOptions = selectedOwner.filter((item) => item !== option);
      dispatch(changeSelectedOwner(newSelectedOptions));
    } else {
      newSelectedOptions = [...selectedOwner, option];
      dispatch(changeSelectedOwner(newSelectedOptions));
    }
  };
  const handleSecondOwnerCheckbox = (option) => {
    let newSelectedOptions;
    if (selectedSecondOwner?.includes(option)) {
      newSelectedOptions = selectedSecondOwner.filter(
        (item) => item !== option
      );
      dispatch(changeSelectedSecondOwner(newSelectedOptions));
    } else {
      newSelectedOptions = [...selectedSecondOwner, option];
      dispatch(changeSelectedSecondOwner(newSelectedOptions));
    }
  };
  const handleFinancialOwnerCheckbox = (option) => {
    let newSelectedOptions;
    if (selectedFinancialOwner?.includes(option)) {
      newSelectedOptions = selectedFinancialOwner.filter(
        (item) => item !== option
      );
      dispatch(changeSelectedFinancialOwner(newSelectedOptions));
    } else {
      newSelectedOptions = [...selectedFinancialOwner, option];
      dispatch(changeSelectedFinancialOwner(newSelectedOptions));
    }
  };

  const handleStatusCheckbox = (option) => {
    let newSelectedOptions;
    if (selectedStatus?.includes(option)) {
      newSelectedOptions = selectedStatus.filter((item) => item !== option);
      dispatch(changeSelectedStatus(newSelectedOptions));
    } else {
      newSelectedOptions = [...selectedStatus, option];
      dispatch(changeSelectedStatus(newSelectedOptions));
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        marginTop: 5,
      }}
      onClick={!anchorEl ? handleOpen : null}
    >
      <div
        style={{
          border: "1px solid gray",
          borderRadius: "50%",
          height: "28px",
          minWidth: "28px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: "5px",
        }}
      >
        {icon}
      </div>
      <FormControl
        sx={{ minWidth: 150, display: "flex", flexDirection: "row", justifyContent: "space-between" }}
        size="small"
      >

        <p>
          <p style={{ margin: 0, fontSize: 10, fontWeight: 400, width: "100%" }}>
            {name}
          </p>

          <p style={{
            margin: 0, fontSize: 14, fontWeight: 400, width: "100%", maxWidth: "80px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}>
            {selectedInitiative}
            {selectedOwner}
            {selectedSecondOwner}
            {selectedFinancialOwner}
            {selectedStatus}
          </p>
        </p>


        <Select
          labelId="custom-multi-select-label"
          id="custom-multi-select"
          variant="standard"
          renderValue={(selected) => { }}
          open={false}
          sx={{
            width: "20%",
            "& .MuiInput-underline:before": {
              borderBottom: "none",
            },
            "& .MuiSelect-standard:after": {
              borderBottom: "none",
            },
          }}
        />
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            sx: {
              width: "320px",
              marginLeft: "-10px",
              marginTop: "15px",
              padding: "10px",
              borderRadius: "12px",
              maxHeight: "450px",
              overflowY: "auto",
              boxShadow: "0px 2px 5px 0px #dae8ff",
            },
          }}
        >
          <div>
            <Accordion sx={{ border: "none", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                ID and Name
              </AccordionSummary>
              <div>
                <input
                  placeholder="Search Initiative"
                  value={searchInitiative}
                  onChange={(e) => setSearchInitiative(e.target.value)}
                  style={{
                    padding: "10px",
                    marginTop: "10px",
                    marginLeft: "10px",
                    marginBottom: "5px",
                  }}
                />
              </div>
              <AccordionDetails>
                <MenuItem
                  className="multi-select-checkbox-list"
                  onClick={() =>
                    handleSelectAll(
                      "initiative",
                      moreFilterData.initiative,
                      selectedInitiative,
                      setSelectAllInitiative,
                      changeSelectedInitiative
                    )
                  }
                >
                  <Checkbox
                    checked={selectAllInitiative}
                    style={{ padding: "0px", color: "#2280ef" }}
                  />
                  <ListItemText primary="Select All" />
                </MenuItem>

                {moreFilterData?.initiative
                  ?.filter((init) =>
                    init
                      ?.toLowerCase()
                      ?.includes(searchInitiative?.toLowerCase())
                  )
                  ?.map((inititative) => (
                    <MenuItem
                      key={inititative}
                      value={inititative}
                      className="multi-select-checkbox-list"
                      onClick={() => handleInitCheckbox(inititative)}
                    >
                      <Checkbox
                        checked={selectedInitiative?.includes(inititative)}
                        style={{ padding: "0px", color: "#2280ef" }}
                      />
                      <ListItemText primary={inititative} />
                    </MenuItem>
                  ))}
              </AccordionDetails>
              <button
                onClick={handleClearInititative}
                fullWidth
                variant="outlined"
                color="primary"
                style={{
                  background: "none",
                  border: "1px solid GrayText",
                  borderRadius: 5,
                  padding: "3px 5px",
                  margin: "5px 0 0 10px",
                  fontSize: 14,
                  color: "GrayText",
                  cursor: "pointer",
                  width: "25%",
                }}
              >
                Clear
              </button>
            </Accordion>
            <Accordion sx={{ border: "none", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Owner
              </AccordionSummary>
              <div>
                <input
                  placeholder="Search Owner"
                  value={searchOwner}
                  onChange={(e) => setSearchOwner(e.target.value)}
                  style={{
                    padding: "10px",
                    marginTop: "10px",
                    marginLeft: "10px",
                    marginBottom: "5px",
                  }}
                />
              </div>
              <AccordionDetails>
                <MenuItem
                  className="multi-select-checkbox-list"
                  onClick={() =>
                    handleSelectAll(
                      "owner",
                      moreFilterData.owner,
                      selectedOwner,
                      setSelectAllOwner,
                      changeSelectedOwner
                    )
                  }
                >
                  <Checkbox
                    checked={selectAllOwner}
                    style={{ padding: "0px", color: "#2280ef" }}
                  />
                  <ListItemText primary="Select All" />
                </MenuItem>

                {moreFilterData?.owner
                  ?.filter((name) =>
                    name?.toLowerCase()?.includes(searchOwner?.toLowerCase())
                  )
                  ?.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      className="multi-select-checkbox-list"
                      onClick={() => handleOwnerCheckbox(name)}
                    >
                      <Checkbox
                        checked={selectedOwner?.includes(name)}
                        style={{ padding: "0px", color: "#2280ef" }}
                      />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
              </AccordionDetails>
              <button
                onClick={handleClearOwner}
                fullWidth
                variant="outlined"
                color="primary"
                style={{
                  background: "none",
                  border: "1px solid GrayText",
                  borderRadius: 5,
                  padding: "3px 5px",
                  margin: "5px 0 0 10px",
                  fontSize: 14,
                  color: "GrayText",
                  cursor: "pointer",
                  width: "25%",
                }}
              >
                Clear
              </button>
            </Accordion>
            <Accordion sx={{ border: "none", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Second Owner
              </AccordionSummary>
              <div>
                <input
                  placeholder="Search Second Owner"
                  value={searchSecondOwner}
                  onChange={(e) => setSearchSecondOwner(e.target.value)}
                  style={{
                    padding: "10px",
                    marginTop: "10px",
                    marginLeft: "10px",
                    marginBottom: "5px",
                  }}
                />
              </div>
              <AccordionDetails>
                <MenuItem
                  className="multi-select-checkbox-list"
                  onClick={() =>
                    handleSelectAll(
                      "delegate",
                      moreFilterData.delegate,
                      selectedSecondOwner,
                      setSelectAllSecondOwner,
                      changeSelectedSecondOwner
                    )
                  }
                >
                  <Checkbox
                    checked={selectAllSecondOwner}
                    style={{ padding: "0px", color: "#2280ef" }}
                  />
                  <ListItemText primary="Select All" />
                </MenuItem>
                {moreFilterData?.delegate
                  ?.filter((name) =>
                    name
                      .toLowerCase()
                      ?.includes(searchSecondOwner?.toLowerCase())
                  )
                  ?.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      className="multi-select-checkbox-list"
                      onClick={() => handleSecondOwnerCheckbox(name)}
                    >
                      <Checkbox
                        checked={selectedSecondOwner?.includes(name)}
                        style={{ padding: "0px", color: "#2280ef" }}
                      />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
              </AccordionDetails>
              <button
                onClick={handleClearSecondOwner}
                fullWidth
                variant="outlined"
                color="primary"
                style={{
                  background: "none",
                  border: "1px solid GrayText",
                  borderRadius: 5,
                  padding: "3px 5px",
                  margin: "5px 0 0 10px",
                  fontSize: 14,
                  color: "GrayText",
                  cursor: "pointer",
                  width: "25%",
                }}
              >
                Clear
              </button>
            </Accordion>
            <Accordion sx={{ border: "none", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Financial Owner
              </AccordionSummary>
              <div>
                <input
                  placeholder="Search Financial Owner"
                  value={searchFinancialOwner}
                  onChange={(e) => setSearchFinancialOwner(e.target.value)}
                  style={{
                    padding: "10px",
                    marginTop: "10px",
                    marginLeft: "10px",
                    marginBottom: "5px",
                  }}
                />
              </div>

              <AccordionDetails>
                <MenuItem
                  className="multi-select-checkbox-list"
                  onClick={() =>
                    handleSelectAll(
                      "financialOwner",
                      moreFilterData.financial_owner,
                      selectedFinancialOwner,
                      setSelectAllFinancialOwner,
                      changeSelectedFinancialOwner
                    )
                  }
                >
                  <Checkbox
                    checked={selectAllFinancialOwner}
                    style={{ padding: "0px", color: "#2280ef" }}
                  />
                  <ListItemText primary="Select All" />
                </MenuItem>
                {moreFilterData?.financial_owner
                  ?.filter((name) =>
                    name
                      ?.toLowerCase()
                      ?.includes(searchFinancialOwner?.toLocaleLowerCase())
                  )
                  ?.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      className="multi-select-checkbox-list"
                      onClick={() => handleFinancialOwnerCheckbox(name)}
                    >
                      <Checkbox
                        checked={selectedFinancialOwner?.includes(name)}
                        style={{ padding: "0px", color: "#2280ef" }}
                      />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
              </AccordionDetails>
              <button
                onClick={handleClearFinancialOwner}
                fullWidth
                variant="outlined"
                color="primary"
                style={{
                  background: "none",
                  border: "1px solid GrayText",
                  borderRadius: 5,
                  padding: "3px 5px",
                  margin: "5px 0 0 10px",
                  fontSize: 14,
                  color: "GrayText",
                  cursor: "pointer",
                  width: "25%",
                }}
              >
                Clear
              </button>
            </Accordion>
            <Accordion sx={{ border: "none", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Is Valid
              </AccordionSummary>
              <AccordionDetails>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-form-control-label-placement"
                    name="position"
                    defaultValue="End"
                    value={selectedIsValid}
                    onChange={(e) =>
                      dispatch(changeSelectedIsValid(e.target.value))
                    }
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <FormControlLabel
                      value={"All"}
                      control={<Radio />}
                      label={"All"}
                    />
                    <FormControlLabel
                      value={"Yes"}
                      control={<Radio />}
                      label={"Yes"}
                    />
                    <FormControlLabel
                      value={"No"}
                      control={<Radio />}
                      label={"No"}
                    />
                  </RadioGroup>
                </FormControl>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ border: "none", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Complexity
              </AccordionSummary>
              <AccordionDetails>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-form-control-label-placement"
                    name="position"
                    defaultValue="End"
                    value={selectedComplexity}
                    onChange={(e) =>
                      dispatch(changeSelectedComplexity(e.target.value))
                    }
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    {moreFilterData?.complexity?.map((complex, index) => (
                      <FormControlLabel
                        key={index}
                        value={complex}
                        control={<Radio />}
                        label={complex}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ border: "none", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Status
              </AccordionSummary>
              <AccordionDetails>
                <MenuItem
                  className="multi-select-checkbox-list"
                  onClick={() =>
                    handleSelectAll(
                      "status",
                      moreFilterData.status,
                      selectedStatus,
                      setSelectAllStatus,
                      changeSelectedStatus
                    )
                  }
                >
                  <Checkbox
                    checked={selectAllStatus}
                    style={{ padding: "0px", color: "#2280ef" }}
                  />
                  <ListItemText primary="Select All" />
                </MenuItem>
                {moreFilterData?.status?.map((status) => (
                  <MenuItem
                    key={status}
                    value={status}
                    className="multi-select-checkbox-list"
                    onClick={() => handleStatusCheckbox(status)}
                  >
                    <Checkbox
                      checked={selectedStatus?.includes(status)}
                      style={{ padding: "0px", color: "#2280ef" }}
                    />
                    <ListItemText primary={status.replaceAll("_", " ")} />
                  </MenuItem>
                ))}
              </AccordionDetails>
              <button
                onClick={handleClearStatus}
                fullWidth
                variant="outlined"
                color="primary"
                style={{
                  background: "none",
                  border: "1px solid GrayText",
                  borderRadius: 5,
                  padding: "3px 5px",
                  margin: "5px 0 0 10px",
                  fontSize: 14,
                  color: "GrayText",
                  cursor: "pointer",
                  width: "25%",
                }}
              >
                Clear
              </button>
            </Accordion>
            <Accordion sx={{ border: "none", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                {`GP ${currentYear}`}
              </AccordionSummary>
              <AccordionDetails>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "48%" }}>
                    <label
                      htmlFor={`gp-${currentYear}-min`}
                      style={{ fontSize: "14px", color: "#b8b8b8" }}
                    >
                      Min
                    </label>
                    <br />
                    <input
                      type="number"
                      id={`gp-${currentYear}-min`}
                      placeholder="enter value"
                      value={selectedGPCurrentYearMin}
                      onChange={(e) =>
                        dispatch(changeSelectedGPCurrentYearMin(e.target.value))
                      }
                      style={{
                        width: "95%",
                        height: "30px",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  <div style={{ width: "48%" }}>
                    <label
                      htmlFor={`gp-${currentYear}-max`}
                      style={{ fontSize: "14px", color: "#b8b8b8" }}
                    >
                      Max
                    </label>
                    <br />
                    <input
                      type="number"
                      id={`gp-${currentYear}-max`}
                      placeholder="enter value"
                      value={selectedGPCurrentYearMax}
                      onChange={(e) =>
                        dispatch(changeSelectedGPCurrentYearMax(e.target.value))
                      }
                      style={{
                        width: "95%",
                        height: "30px",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ border: "none", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                {`GP ${currentYear + 1}`}
              </AccordionSummary>
              <AccordionDetails>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "48%" }}>
                    <label
                      htmlFor={`gp-${currentYear + 1}-min`}
                      style={{ fontSize: "14px", color: "#b8b8b8" }}
                    >
                      Min
                    </label>
                    <br />
                    <input
                      type="number"
                      id={`gp-${currentYear + 1}-min`}
                      placeholder="enter value"
                      value={selectedGPNextYearMin}
                      onChange={(e) =>
                        dispatch(changeSelectedGPNextYearMin(e.target.value))
                      }
                      style={{
                        width: "95%",
                        height: "30px",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  <div style={{ width: "48%" }}>
                    <label
                      htmlFor={`gp-${currentYear + 1}-max`}
                      style={{ fontSize: "14px", color: "#b8b8b8" }}
                    >
                      Max
                    </label>
                    <br />
                    <input
                      type="number"
                      id={`gp-${currentYear + 1}-max`}
                      placeholder="enter value"
                      value={selectedGPNextYearMax}
                      onChange={(e) =>
                        dispatch(changeSelectedGPNextYearMax(e.target.value))
                      }
                      style={{
                        width: "95%",
                        height: "30px",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ border: "none", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                {`GP ${currentYear + 2}`}
              </AccordionSummary>
              <AccordionDetails>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "48%" }}>
                    <label
                      htmlFor={`gp-${currentYear + 2}-min`}
                      style={{ fontSize: "14px", color: "#b8b8b8" }}
                    >
                      Min
                    </label>
                    <br />
                    <input
                      type="number"
                      id={`gp-${currentYear + 2}-min`}
                      placeholder="enter value"
                      value={selectedGPYearAfterNextMax}
                      onChange={(e) =>
                        dispatch(
                          changeSelectedGPYearAfterNextMin(e.target.value)
                        )
                      }
                      style={{
                        width: "95%",
                        height: "30px",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  <div style={{ width: "48%" }}>
                    <label
                      htmlFor={`gp-${currentYear + 2}-max`}
                      style={{ fontSize: "14px", color: "#b8b8b8" }}
                    >
                      Max
                    </label>
                    <br />
                    <input
                      type="number"
                      id={`gp-${currentYear + 2}-max`}
                      placeholder="enter value"
                      value={selectedGPYearAfterNextMax}
                      onChange={(e) =>
                        dispatch(
                          changeSelectedGPYearAfterNextMax(e.target.value)
                        )
                      }
                      style={{
                        width: "95%",
                        height: "30px",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

            <div
              style={{
                display: "flex",
                padding: 2,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                onClick={handleClear}
                fullWidth
                variant="outlined"
                color="primary"
                style={{
                  background: "none",
                  border: "1px solid GrayText",
                  borderRadius: 5,
                  padding: "5px 10px",
                  margin: "0 5px",
                  fontSize: 14,
                  color: "GrayText",
                  cursor: "pointer",
                  width: "45%",
                }}
              >
                Clear
              </button>
              <button
                onClick={handleApply}
                fullWidth
                variant="contained"
                color="primary"
                style={{
                  background: "#2280EF",
                  color: "#fff",
                  border: "1px solid #2280EF",
                  borderRadius: 5,
                  padding: "5px 10px",
                  margin: "0 5px",
                  fontSize: 14,
                  cursor: "pointer",
                  width: "45%",
                }}
              >
                Apply Filter
              </button>
            </div>
          </div>
        </Popover>
      </FormControl>
    </div>
  );
};

export default MoreFilterComponent;
