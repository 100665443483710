export const difficultyList = ["Low", "Medium", "High"];

export const ClassificationList = [
  "For Discussion",
  "Convert into Initiative",
  "Finalized",
  "Invalid",
];

export const prioriyList = ["High", "Medium", "Low"];

export const isReport = ["No", "Yes"];
