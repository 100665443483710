import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, Popover, Select, Checkbox, Grid } from "@mui/material";
import InterestsOutlinedIcon from "@mui/icons-material/InterestsOutlined";
import AccordionMultiCheckboxFilter from "./AccordionMultiCheckboxFilter";
import {
  changeCategory,
  changeCategorySector,
} from "../../../redux/reducers/InitiativeSlice";

const CategoryFilter = ({ componentName }) => {
  const dispatch = useDispatch();
  const { currentCategory } = useSelector((state) => state.InitiativeSlice);
  const [filtersData, setFiltersData] = useState([]);
  const [reportLoaded, setReportLoaded] = useState(false);
  const [token, setToken] = useState(null);
  const [selectedSectors, setSelectedSectors] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    const idToken = localStorage.getItem("token");
    if (idToken) {
      setToken(idToken);
    }
  }, []);

  useEffect(() => {
    const fetchFilterData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/filters/margin-walk`,
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response.data.data;
        setFiltersData(data);
      } catch (error) {
        console.error("Error fetching margin walk filter data:", error);
      }
    };

    if (token) {
      fetchFilterData();
    }
  }, [token]);

  useEffect(() => {
    if (window.report) {
      // console.log("report loaded from category:::", window.report);
      const report = window.report;
      report.on("loaded", () => {
        setReportLoaded(true);
      });
    } else {
      setReportLoaded(true);
    }
    // console.log("Current Category from Redux :::", currentCategory);
  }, [currentCategory, dispatch]);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedSectors([]);
      setSelectedCategories([]);
    } else {
      const allSectors = filtersData.map((item) => item.sector);
      setSelectedSectors(allSectors);
      const allCategories = filtersData.flatMap((item) => item.category);
      setSelectedCategories(allCategories);
    }
  };

  const handleApply = () => {
    const selectedSectorsWithCategories = filtersData
      .filter((item) => {
        const selectedCatsForSector = item.category.filter((cat) =>
          selectedCategories.includes(cat)
        );
        return selectedCatsForSector.length > 0;
      })
      .map((item) => item.sector);

    // console.log("Selected1 Sectors :::", selectedSectorsWithCategories);
    // console.log("Selected1 Category :::", selectedCategories);

    dispatch(changeCategory(selectedCategories));
    dispatch(changeCategorySector(selectedSectorsWithCategories));
    handleClose();
  };

  const handleSectorChange = (sector) => {
    const isSelected = selectedSectors.includes(sector);
    if (isSelected) {
      setSelectedSectors(selectedSectors.filter((s) => s !== sector));
      setSelectedCategories(
        selectedCategories.filter(
          (cat) =>
            !filtersData
              .find((item) => item.sector === sector)
              .category.includes(cat)
        )
      );
    } else {
      setSelectedSectors([...selectedSectors, sector]);
      const categoriesToAdd =
        filtersData.find((item) => item.sector === sector)?.category || [];
      setSelectedCategories([
        ...selectedCategories,
        ...categoriesToAdd.filter((cat) => !selectedCategories.includes(cat)),
      ]);
    }
  };

  useEffect(() => {
    const allSectors = filtersData.map((item) => item.sector);
    setSelectAll(
      allSectors.length > 0 &&
        allSectors.every((sector) => selectedSectors.includes(sector))
    );
  }, [selectedSectors, filtersData]);

  const open = Boolean(anchorEl);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        margin: "0 10px",
        background: "#fff",
        padding: "5px 10px",
        borderRadius: 8,
        border: "1px solid #E8EFF7",
        boxShadow: "0px 2px 5px 0px #DAE8FF",
      }}
      onClick={!anchorEl ? handleOpen : null}
    >
      <div
        style={{
          border: "1px solid gray",
          borderRadius: "50%",
          height: "28px",
          minWidth: "28px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: "5px",
        }}
      >
        <InterestsOutlinedIcon sx={{ fontSize: "18px", color: "#373B5C" }} />
      </div>
      <FormControl
        sx={{ minWidth: 150, display: "flex", flexDirection: "row" }}
        size="small"
      >
        <p style={{ margin: 0, color: "#373B5C" }}>Sector/Category</p>
        <Select
          labelId="custom-multi-select-label"
          id="custom-multi-select"
          variant="standard"
          open={false}
          sx={{
            "& .MuiInput-underline:before": {
              borderBottom: "none",
            },
            "& .MuiSelect-standard:after": {
              borderBottom: "none",
            },
          }}
        />
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            sx: {
              width: "270px",
              marginLeft: "-10px",
              marginTop: "5px",
              padding: "10px",
              borderRadius: "12px",
              maxHeight: "450px",
              overflowY: "auto",
              boxShadow: "0px 2px 5px 0px #DAE8FF",
            },
          }}
        >
          <div>
            {reportLoaded && (
              <>
                <div>
                  <Grid container style={{ display: "flex", padding: "10px" }}>
                    <Grid xs={1} style={{ display: "flex" }} item>
                      <Checkbox
                        checked={selectAll}
                        onChange={handleSelectAll}
                        className="multi-select-checkbox-list"
                        style={{ padding: "0px", color: "#2280ef" }}
                      />
                    </Grid>
                    <Grid xs={11} item>
                      <div style={{ padding: "0px 8px" }}>
                        Select All Sectors
                      </div>
                    </Grid>
                  </Grid>

                  {filtersData.map((x) => (
                    <Grid container key={x.sector} style={{ display: "flex" }}>
                      <Grid item
                        xs={1}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          marginTop: "8px",
                        }}
                      >
                        <Checkbox
                          checked={selectedSectors.includes(x.sector)}
                          onChange={() => handleSectorChange(x.sector)}
                          style={{ padding: "0px", color: "#2280ef" }}
                        />
                      </Grid>
                      <Grid xs={11} item>
                        <AccordionMultiCheckboxFilter
                          name={x.sector}
                          dataList={x.category}
                          selectedCategories={selectedCategories}
                          setSelectedCategories={setSelectedCategories}
                          selectedSectors={selectedSectors}
                          setSelectedSectors={setSelectedSectors}
                        />
                      </Grid>
                    </Grid>
                  ))}
                </div>
                <div
                  style={{
                    display: "flex",
                    padding: 2,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    onClick={handleClose}
                    fullWidth
                    variant="outlined"
                    color="primary"
                    style={{
                      background: "none",
                      border: "1px solid GrayText",
                      borderRadius: 5,
                      padding: "5px 10px",
                      margin: "0 5px",
                      fontSize: 14,
                      color: "GrayText",
                      cursor: "pointer",
                      width: "45%",
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleApply}
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{
                      background: "#2280EF",
                      color: "#fff",
                      border: "1px solid #2280EF",
                      borderRadius: 5,
                      padding: "5px 10px",
                      margin: "0 5px",
                      fontSize: 14,
                      cursor: "pointer",
                      width: "45%",
                    }}
                  >
                    Apply Filter
                  </button>
                </div>
              </>
            )}
          </div>
        </Popover>
      </FormControl>
    </div>
  );
};

export default CategoryFilter;
