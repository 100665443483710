import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentSector: [],
  currentMarket: [],
  currentCategory: [],
  currentLever: [],

  isAllSector: true,
  isAllMarket: true,
  isAllCategory: true,
  isAllLever: true,

  overviewStatus: "idle",
  overviewTableRowLength: 0,
  overviewPaginationModel: {
    pageSize: 5,
    page: 0,
  },
  overviewPageNo: 1,
  pageLimit: 10,
  financialStatus: "idle",

  playgroundTargetMarginStatus: "idle",
  playgroundHeadwindsTailwindsStatus: "idle",
  playgroundPlanConfidenceStatus: "idle",

  playgroundTableData: [],
  playgroundPlanConfidenceTableData: [],
  playgroundHeadwindsTailwindsTableData: [],
  sortModel: [],
  tablePostData: {
    pageNo: 1,
    limit: 5,
    sortBy: "gp_2024",
    direction: "desc",
  },
};

const createReducer = (key) => (state, action) => {
  console.log("state12121", key, state, action);
  state[key] = action.payload;
};

export const fetchPlaygroundGetTargetMarginData = createAsyncThunk(
  "Playground/fetchPlaygroundGetTargetMarginData",
  async (postData, { rejectWithValue }) => {
    try {
      const idToken = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/playground/get-target-margin`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      console.log("Playground Response :::", response);
      const targetMarginData = response.data.data.map((row, index) => ({
        ...row,
        id: index,
        editable: true,
      }));
      console.log("qqqquniqID :::", targetMarginData);
      return { data: targetMarginData };
    } catch (error) {
      console.error("Error fetching target margin data", error);
      return rejectWithValue(error.response.data); // Handle error and reject
    }
  }
);

export const fetchPlaygroundGetHeadwindTailwindsData = createAsyncThunk(
  "Playground/fetchPlaygroundGetHeadwindTailwindsData",
  async (postData) => {
    const idToken = localStorage.getItem("token");
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/playground/get-headwinds-tailwinds`,
      postData,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
    console.log("Playground Response :::", response);
    const targetMarginData = response.data.data.map((row, index) => ({
      ...row,
      id: index,
      editable: true,
    }));
    console.log("qqqquniqID :::", targetMarginData);
    return { data: targetMarginData };
  }
);

export const fetchPlaygroundGetPlanConfidenceData = createAsyncThunk(
  "Playground/fetchPlaygroundGetPlanConfidenceData",
  async (postData) => {
    const idToken = localStorage.getItem("token");
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/playground/get-plan-confidence`,
      postData,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
    console.log("Playground Response :::", response);
    const planConfidenceData = response.data.data.map((row, index) => ({
      ...row,
      id: index,
      editable: true,
    }));
    console.log("planConfidenceData :::", planConfidenceData);
    return { data: planConfidenceData };
  }
);

const PlaygroundSlice = createSlice({
  name: "Playground",
  initialState,
  reducers: {
    handleSortModel: (state, action) => {
      state.sortModel = [...action.payload];
    },
    handlePageLimitChange: (state, action) => {
      state.pageLimit = action.payload;
    },
    handleOverviewPageNo: (state, action) => {
      state.overviewPageNo = action.payload;
    },
    handleOverviewPagination: (state, action) => {
      state.overviewPaginationModel = action.payload;
    },
    handleChaangeTablePostData: (state, action) => {
      state.tablePostData = action.payload;
    },

    changeSector: createReducer("currentSector"),
    changeCategory: createReducer("currentCategory"),
    changeMarket: createReducer("currentMarket"),
    changeLever: createReducer("currentLever"),

    changeIsAllSector: createReducer("isAllSector"),
    changeIsAllMarket: createReducer("isAllMarket"),
    changeIsAllCategory: createReducer("isAllCategory"),
    changeIsAllLever: createReducer("isAllLever"),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlaygroundGetTargetMarginData.pending, (state) => {
        state.playgroundTargetMarginStatus = "loading";
        state.playgroundTableData = [];
        state.playgroundHeadwindsTailwindsTableData = [];
        state.playgroundPlanConfidenceTableData = [];
      })
      .addCase(fetchPlaygroundGetTargetMarginData.rejected, (state) => {
        state.playgroundTargetMarginStatus = "failed";
        state.playgroundTableData = [];
        state.playgroundHeadwindsTailwindsTableData = [];
        state.playgroundPlanConfidenceTableData = [];
        state.overviewTableRowLength = 0;
      })

      .addCase(
        fetchPlaygroundGetTargetMarginData.fulfilled,
        (state, action) => {
          state.playgroundTargetMarginStatus = "succeeded";
          state.playgroundTableData = action.payload.data;
          state.overviewTableRowLength = action.payload.totalCount;
        }
      )

      .addCase(fetchPlaygroundGetPlanConfidenceData.pending, (state) => {
        state.playgroundPlanConfidenceStatus = "loading";
        state.playgroundTableData = [];
        state.playgroundHeadwindsTailwindsTableData = [];
        state.playgroundPlanConfidenceTableData = [];
      })
      .addCase(fetchPlaygroundGetPlanConfidenceData.rejected, (state) => {
        state.playgroundPlanConfidenceStatus = "failed";
        state.playgroundTableData = [];
        state.playgroundHeadwindsTailwindsTableData = [];
        state.playgroundPlanConfidenceTableData = [];
        state.overviewTableRowLength = 0;
      })
      .addCase(
        fetchPlaygroundGetPlanConfidenceData.fulfilled,
        (state, action) => {
          state.playgroundPlanConfidenceStatus = "succeeded";
          state.playgroundPlanConfidenceTableData = action.payload.data;
          state.overviewTableRowLength = action.payload.totalCount;
        }
      )

      .addCase(fetchPlaygroundGetHeadwindTailwindsData.pending, (state) => {
        state.playgroundHeadwindsTailwindsStatus = "loading";
        state.playgroundTableData = [];
        state.playgroundHeadwindsTailwindsTableData = [];
        state.playgroundPlanConfidenceTableData = [];
      })
      .addCase(fetchPlaygroundGetHeadwindTailwindsData.rejected, (state) => {
        state.playgroundHeadwindsTailwindsStatus = "failed";
        state.playgroundTableData = [];
        state.playgroundHeadwindsTailwindsTableData = [];
        state.playgroundPlanConfidenceTableData = [];
        state.overviewTableRowLength = 0;
      })
      .addCase(
        fetchPlaygroundGetHeadwindTailwindsData.fulfilled,
        (state, action) => {
          state.playgroundHeadwindsTailwindsStatus = "succeeded";
          state.playgroundHeadwindsTailwindsTableData = action.payload.data;
          state.overviewTableRowLength = action.payload.totalCount;
        }
      );
  },
});

console.log("init11", initialState);

export const {
  handleSortModel,
  handlePageLimitChange,
  handleOverviewPageNo,

  changeCategory,
  changeSector,
  changeMarket,
  changeLever,

  changeIsAllSector,
  changeIsAllMarket,
  changeIsAllCategory,
  changeIsAllLever,

  handleOverviewPagination,
  handleChaangeTablePostData,
} = PlaygroundSlice.actions;

export default PlaygroundSlice.reducer;
