import React, { useEffect, useState } from "react";
import FilterItem from "./components/FilterItem";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import InterestsOutlinedIcon from "@mui/icons-material/InterestsOutlined";
import ElevatorOutlinedIcon from "@mui/icons-material/ElevatorOutlined";
import { Button } from "@mui/material";
import CustomMultiSelect from "./components/CustomMultiSelect";
import MoreFilterComponent from "./components/MoreFilterComponent";
import SingleSelect from "./components/SingleSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  changeDriver,
  changeIsAllDriver,
  changeIsAllLever,
  changeIsAllSector,
  changeLever,
  changeSector,
} from "../../redux/reducers/InitiativeSlice";
import "../../styles/filter.style.scss";
import {
  fetchInitiativeFinancialData,
  fetchInitiativeOverviewData,
  handleOverviewPagination,
  changeSelectedClassification,
  changeSelectedComplexity,
  changeSelectedFinancialOwner,
  changeSelectedGPCurrentYearMax,
  changeSelectedGPCurrentYearMin,
  changeSelectedGPNextYearMax,
  changeSelectedGPNextYearMin,
  changeSelectedGPYearAfterNextMax,
  changeSelectedGPYearAfterNextMin,
  changeSelectedInitiative,
  changeSelectedIsValid,
  changeSelectedLog,
  changeSelectedOwner,
  changeSelectedSecondOwner,
  changeSelectedStatus,
  changeSelectedTags,
} from "../../redux/reducers/InitiativeSlice";
import { CONSTANTS } from "../../data/constants";
import axios from "axios";

const InititativeFilters = () => {
  const [sectorList, setSectorList] = useState([]);
  const [leverList, setLeverList] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const [token, setToken] = useState(null);
  const currentYear = CONSTANTS?.currentYear;
  const logsList = [{ value: "All", type: "All" }, { value: "OPPORTUNITY", type: "Opportunity" }, { value: "INITIATIVE", type: "Initiative" }]
  // const [isAllSector, setIsAllSector] = useState(true);
  // const [isAllLever, setIsAllLever] = useState(true);
  // const [isAllDriver, setIsAllDriver] = useState(true);

  const dispatch = useDispatch();
  const {
    currentSector,
    currentCategorySector,
    currentLever,
    currentDriver,
    isAllSector,
    selectedLog,
    isAllLever,
    isAllDriver,
    currentTab,
    overviewPaginationModel,
  } = useSelector((state) => state.InitiativeSlice);

  useEffect(() => { }, [
    dispatch,
    currentSector,
    currentCategorySector,
    currentLever,
    currentDriver,
    isAllSector,
    isAllLever,
    isAllDriver,
  ]);

  useEffect(() => {
    const idToken = localStorage.getItem("token");
    if (idToken) {
      setToken(idToken);
    }
  }, []);

  useEffect(() => {
    const fetchFiltersList = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/filters`,
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.data.data;
        console.log("Filter Response Data :::", data);
        setSectorList(data.sector);
        setLeverList(data.lever);
        setDriverList(data.driver);
      } catch (error) {
        console.error("Error fetching filters:", error);
      }
    };
    if (token) {
      fetchFiltersList();
    }
  }, [token]);

  useEffect(() => {
    let filters = {
      sector: isAllSector ? [] : currentSector,
      lever: isAllLever ? [] : currentLever,
    };

    const fetchUpdatedFilterList = async () => {
      if (isAllLever) {
        setLeverList([]);
        setDriverList([]);
      }
      if (!isAllLever) {
        setDriverList([]);
      }
      setDriverList([]);
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/filters/new`,
        { filters },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.data.data;
      console.log("Filtered Filter List :::", data);
      if (isAllLever) {
        setLeverList(data.lever);
        setDriverList(data.driver);
      }
      if (!isAllLever) {
        setDriverList(data.driver);
      }
    };
    if (token) {
      fetchUpdatedFilterList();
    }
  }, [token, isAllSector, currentSector, isAllLever, currentLever]);

  const handleclear = () => {
    let newSectorOptions;
    let newLeverOptions;
    let newDriverOptions;
    if (isAllSector) {
      newSectorOptions = [];
      dispatch(changeIsAllSector(false));
    } else {
      newSectorOptions = sectorList;
      dispatch(changeIsAllSector(true));
    }
    if (isAllLever) {
      newLeverOptions = [];
      dispatch(changeIsAllLever(false));
    } else {
      newLeverOptions = leverList;
      dispatch(changeIsAllLever(true));
    }
    if (isAllDriver) {
      newDriverOptions = [];
      dispatch(changeIsAllDriver(false));
    } else {
      newDriverOptions = driverList;
      dispatch(changeIsAllDriver(true));
    }
    dispatch(changeSector(newSectorOptions));
    dispatch(changeLever(newLeverOptions));
    dispatch(changeDriver(newDriverOptions));
    dispatch(changeSelectedGPCurrentYearMin(0));
    dispatch(changeSelectedGPCurrentYearMax(0));
    dispatch(changeSelectedGPNextYearMin(0));
    dispatch(changeSelectedGPNextYearMax(0));
    dispatch(changeSelectedGPYearAfterNextMin(0));
    dispatch(changeSelectedGPYearAfterNextMax(0));
    dispatch(changeSelectedInitiative([]));
    dispatch(changeSelectedOwner([]));
    dispatch(changeSelectedSecondOwner([]));
    dispatch(changeSelectedFinancialOwner([]));
    dispatch(changeSelectedTags([]));
    dispatch(changeSelectedStatus([]));
    // dispatch(changeSelectedStatus(["NOT_STARTED", "ON_TRACK", "OFF_TRACK"]));
    dispatch(changeSelectedIsValid("All"));
    dispatch(changeSelectedComplexity(""));
    dispatch(changeSelectedClassification(""));
    dispatch(changeSelectedLog("All"))
    let filterObj = {
      sector: [],
      lever: [],
      driver: [],
      inv_type: ["OPPORTUNITY", "INITIATIVE"],
    };

    let filterData = {
      pageNo: 1,
      limit: overviewPaginationModel?.pageSize,
      sortBy: `gp_${currentYear}`,
      direction: "desc",
      filters: filterObj,
    };
    if (currentTab == "initiative-overview") {
      dispatch(fetchInitiativeOverviewData(filterData));
    }
    if (currentTab == "imm-financial") {
      dispatch(fetchInitiativeFinancialData(filterData));
    }
  };

  return (
    <div className="init-filter-area">

      <div className="init-filter-list" style={{ justifyContent: "center" }}>
        <div className="init-filter-item">
          <CustomMultiSelect
            name={"Sector"}
            options={sectorList}
            selectedOptions={currentSector}
            setSelectedOptions={changeSector}
            isAllSelected={isAllSector}
            setIsAllSelected={changeIsAllSector}
            icon={<InterestsOutlinedIcon sx={{ fontSize: "18px" }} />}
          />
        </div>
        <div className="init-filter-item">
          <CustomMultiSelect
            name={"Lever"}
            options={leverList}
            selectedOptions={currentLever}
            setSelectedOptions={changeLever}
            isAllSelected={isAllLever}
            setIsAllSelected={changeIsAllLever}
            icon={<ElevatorOutlinedIcon sx={{ fontSize: "20px" }} />}
          />
        </div>
        <div className="init-filter-item">
          <CustomMultiSelect
            name={"Driver"}
            options={driverList}
            selectedOptions={currentDriver}
            setSelectedOptions={changeDriver}
            isAllSelected={isAllDriver}
            setIsAllSelected={changeIsAllDriver}
            icon={<PersonOutlinedIcon sx={{ fontSize: "20px" }} />}
          />
        </div>
        <div className="init-filter-item">
          <SingleSelect
            name={"Type"}
            options={logsList}
            selectedOptions={selectedLog}
            setSelectedOptions={changeSelectedLog}
            icon={<InterestsOutlinedIcon sx={{ fontSize: "18px" }} />}
          />
        </div>
        <div className="init-filter-item">
          <MoreFilterComponent
            name={"More Filters"}
            options="demo "
            selectedOptions="asasas"
            // list={["All"]}
            // data={["All"]}
            // setData={setDriver}
            icon={<FilterListOutlinedIcon sx={{ fontSize: "20px" }} />}
          />
        </div>
        <Button
          sx={{
            background: "#fff",
            borderRadius: "8px",
            boxShadow: "0 2px 5px 0 #dae8ff !important",
            color: "#373b5c",
            fontSize: "12px",
            fontWeight: 500,
            height: "52px",
            marginRight: "10px",
            padding: "6px 8px",
            width: "180px",
            "&:hover": {
              background: "#f0f0f0", // Optional hover effect
            },
          }}
          onClick={handleclear}
        >
          Clear
        </Button>
        {/* <div className="init-filter-item">
          <FilterItem
            name={"Order By"}
            list={["All"]}
            data={["All"]}
            // setData={setDriver}
            icon={<AssignmentOutlinedIcon sx={{ fontSize: "18px" }} />}
          />
        </div> */}
      </div>
    </div>
  );
};

export default InititativeFilters;
