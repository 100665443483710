import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import InitiativeSlice from "../reducers/InitiativeSlice";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import ScoreCardSlice from "../reducers/ScoreCardSlice";
import PlaygroundSlice from "../reducers/PlaygroundSlice";

const reducers = combineReducers({
  PlaygroundSlice: PlaygroundSlice,
  InitiativeSlice: InitiativeSlice,
  ScoreCardSlice,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, REGISTER],
      },
    }),
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
