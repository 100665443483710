import React, { useEffect, useState } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { useSelector } from "react-redux";
import axios from "axios";
import CustomAlert from "../../components/CustomAlert";

const reportId = process.env.REACT_APP_MULTI_YEAR_INITIATIVE_BY_LEVER_REPORT_ID;
const groupId = process.env.REACT_APP_PBI_GROUP_ID;

const MultiYearInitiativesByLever = () => {
  const [PBItokens, setPBItokens] = useState({});
  const [filters, setFilters] = useState([]);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");

  useEffect(() => {
    const fetchPBIToken = async () => {
      try {
        const idToken = localStorage.getItem("token");
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/power-bi/token`,
          {
            reportId: reportId,
            groupId: groupId,
          },
          {
            headers: {
              authorization: `Bearer ${idToken}`,
            },
          }
        );
        const data = await response.data.data;
        console.log("PBI token resp ::", data);
        setPBItokens(data);
      } catch (error) {
        console.error("Error while fetching PBI token...", error);
        setAlertMessage(error?.message);
        setAlertType("error");
        setAlertVisible(true);
      }
    };

    fetchPBIToken();
  }, []);

  return (
    <div className="container-fullpage">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          gridColumn: "span 12",
        }}
      >
        <div>
          <div style={{ fontSize: 16, fontWeight: 600 }}>
            Multi-Year Initiatives By Lever
          </div>
          <div style={{ fontSize: 14, fontWeight: 400, color: "#6B6B6B" }}>
            Take a look at the overall status
          </div>
        </div>
      </div>

      <div className="pbi-chart">
        <PowerBIEmbed
          embedConfig={{
            type: "report", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
            id: reportId,
            embedUrl: PBItokens?.embedUrl,
            accessToken: PBItokens?.embedToken,
            tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
            //   filters: filters,
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },
                pageNavigation: {
                  visible: false, // Hides the navigation pane
                },
              },
              background: models.BackgroundType.Transparent,
            },
          }}
          eventHandlers={
            new Map([
              [
                "loaded",
                function () {
                  console.log("Report loaded");
                },
              ],
              [
                "rendered",
                function () {
                  console.log("Report rendered");
                },
              ],
              [
                "error",
                function (event) {
                  console.log(event.detail);
                },
              ],
              ["visualClicked", () => console.log("visual clicked")],
              ["pageChanged", (event) => console.log(event)],
            ])
          }
          cssClassName={"multiYearByLeverScreen"}
          getEmbeddedComponent={(embeddedReport) => {
            window.report = embeddedReport;
          }}
        />
      </div>
      <CustomAlert
        message={alertMessage}
        type={alertType}
        visible={alertVisible}
        onClose={() => setAlertVisible(false)}
      />
    </div>
  );
};

export default MultiYearInitiativesByLever;
