import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CONSTANTS } from "../../data/constants";

const initialState = {
  currentTab: "initiative-overview",
  currentSubTab: "imm-impact-vs-plan",
  currentSector: [],
  currentLever: [],
  currentDriver: [],
  isAllSector: true,
  isAllLever: true,
  isAllDriver: true,
  currentCategory: [],
  currentCategorySector: [],
  selectedInitiative: [],
  selectedOwner: [],
  selectedSecondOwner: [],
  selectedFinancialOwner: [],
  selectedIsValid: "All",
  selectedLog: "All",
  selectedComplexity: "",
  selectedClassification: "",
  selectedTags: [],
  selectedStatus: [],
  //  : ["NOT_STARTED", "ON_TRACK", "OFF_TRACK"],
  selectedGPCurrentYearMin: 0,
  selectedGPCurrentYearMax: 0,
  selectedGPNextYearMin: 0,
  selectedGPNextYearMax: 0,
  selectedGPYearAfterNextMin: 0,
  selectedGPYearAfterNextMax: 0,
  overviewStatus: "idle",
  overviewTableData: [],
  overviewTableRowLength: 0,
  financialTableRowLength: 0,
  overviewPaginationModel: {
    pageSize: 5,
    page: 0,
  },
  overviewPageNo: 1,
  pageLimit: 10,
  financialStatus: "idle",
  financialTableData: [],
  sortModel: [],
  tablePostData: {
    pageNo: 1,
    limit: 5,
    sortBy: `gp_${CONSTANTS?.currentYear}`,
    direction: "desc",
  },
};

const createReducer = (key) => (state, action) => {
  state[key] = action.payload;
};

export const fetchInitiativeOverviewData = createAsyncThunk(
  "table/fetchInitiativeOverviewData",
  async (postData) => {
    try {
      const idToken = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/home/initiative-overview`,
        postData,
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }
      );
      console.log("Overview Response :::", response);
      let dataWithUniqueId = response?.data?.data?.logs?.map((row, index) => {
        if (row?.name !== "Total") {
          return { ...row, uid: row.src_id + index };
        } else {
          return { ...row, uid: " " };
        }
      });
      let totalCount = response?.data?.data?.totalCount;
      console.log("Overview Response uniqID :::", dataWithUniqueId);
      return { data: dataWithUniqueId, totalCount };
    } catch (error) {
      console.error("Error fetching initiative overview data:", error);
      throw error;
    }
  }
);

export const fetchInitiativeFinancialData = createAsyncThunk(
  "table/fetchInitiativeInitiativeData",
  async (postData) => {
    try {
      const idToken = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/home/imm-financial`,
        postData,
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }
      );
      console.log("Financial Response :::", response);
      let dataWithUniqueId = response?.data?.data?.logs?.map((row, index) => {
        if (row?.name !== "Total") {
          return { ...row, uid: row.src_id + index };
        } else {
          return { ...row, uid: " " };
        }
      });
      let totalCount = response?.data?.data?.totalCount;
      console.log("Financial Response with unique ID :::", dataWithUniqueId);
      return { data: dataWithUniqueId, totalCount };
    } catch (error) {
      console.error("Error fetching financial data:", error);
      throw error;
    }
  }
);

const InitiativeSlice = createSlice({
  name: "Inititative",
  initialState,
  reducers: {
    changeTab: (state, action) => {
      state.currentTab = action.payload;
    },
    changeSubtab: (state, action) => {
      state.currentSubTab = action.payload;
    },
    handleSortModel: (state, action) => {
      state.sortModel = [...action.payload];
    },
    handlePageLimitChange: (state, action) => {
      state.pageLimit = action.payload;
    },
    handleOverviewPageNo: (state, action) => {
      state.overviewPageNo = action.payload;
    },
    handleOverviewPagination: (state, action) => {
      state.overviewPaginationModel = action.payload;
    },
    handleChaangeTablePostData: (state, action) => {
      state.tablePostData = action.payload;
    },
    changeCategory: createReducer("currentCategory"),
    changeCategorySector: createReducer("currentCategorySector"),
    changeSector: createReducer("currentSector"),
    changeLever: createReducer("currentLever"),
    changeDriver: createReducer("currentDriver"),
    changeIsAllSector: createReducer("isAllSector"),
    changeIsAllLever: createReducer("isAllLever"),
    changeIsAllDriver: createReducer("isAllDriver"),
    changeSelectedInitiative: createReducer("selectedInitiative"),
    changeSelectedOwner: createReducer("selectedOwner"),
    changeSelectedSecondOwner: createReducer("selectedSecondOwner"),
    changeSelectedFinancialOwner: createReducer("selectedFinancialOwner"),
    changeSelectedIsValid: createReducer("selectedIsValid"),
    changeSelectedLog: createReducer("selectedLog"),
    changeSelectedComplexity: createReducer("selectedComplexity"),
    changeSelectedClassification: createReducer("selectedClassification"),
    changeSelectedTags: createReducer("selectedTags"),
    changeSelectedStatus: createReducer("selectedStatus"),
    changeSelectedGPCurrentYearMin: createReducer("selectedGPCurrentYearMin"),
    changeSelectedGPCurrentYearMax: createReducer("selectedGPCurrentYearMax"),
    changeSelectedGPNextYearMin: createReducer("selectedGPNextYearMin"),
    changeSelectedGPNextYearMax: createReducer("selectedGPNextYearMax"),
    changeSelectedGPYearAfterNextMin: createReducer(
      "selectedGPYearAfterNextMin"
    ),
    changeSelectedGPYearAfterNextMax: createReducer(
      "selectedGPYearAfterNextMax"
    ),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInitiativeOverviewData.pending, (state) => {
        state.overviewStatus = "loading";
        state.overviewTableData = [];
        state.financialTableData = [];
      })
      .addCase(fetchInitiativeOverviewData.rejected, (state) => {
        state.overviewStatus = "failed";
        state.overviewTableData = [];
        state.financialTableData = [];
        state.overviewTableRowLength = 0;
      })
      .addCase(fetchInitiativeOverviewData.fulfilled, (state, action) => {
        state.overviewStatus = "succeeded";
        state.overviewTableData = action.payload.data;
        state.overviewTableRowLength = action.payload.totalCount;
      })
      .addCase(fetchInitiativeFinancialData.pending, (state) => {
        state.financialStatus = "loading";
        state.financialTableData = [];
        state.overviewTableData = [];
      })
      .addCase(fetchInitiativeFinancialData.rejected, (state) => {
        state.financialStatus = "failed";
        state.overviewTableData = [];
        state.financialTableData = [];
        state.overviewTableRowLength = 0;
      })
      .addCase(fetchInitiativeFinancialData.fulfilled, (state, action) => {
        state.financialStatus = "succeeded";
        state.financialTableData = action.payload.data;
        state.overviewTableRowLength = action.payload.totalCount;
      });
  },
});

export const {
  changeTab,
  changeSubtab,
  handleShowChart,
  handleSortModel,
  handlePageLimitChange,
  handleOverviewPageNo,
  changeCategory,
  changeCategorySector,
  changeSector,
  changeLever,
  changeDriver,
  changeIsAllSector,
  changeIsAllLever,
  changeIsAllDriver,
  handleOverviewPagination,
  handleChaangeTablePostData,
  changeSelectedClassification,
  changeSelectedComplexity,
  changeSelectedFinancialOwner,
  changeSelectedGPCurrentYearMax,
  changeSelectedGPCurrentYearMin,
  changeSelectedGPNextYearMax,
  changeSelectedGPNextYearMin,
  changeSelectedGPYearAfterNextMax,
  changeSelectedGPYearAfterNextMin,
  changeSelectedInitiative,
  changeSelectedIsValid,
  changeSelectedLog,
  changeSelectedOwner,
  changeSelectedSecondOwner,
  changeSelectedStatus,
  changeSelectedTags,
} = InitiativeSlice.actions;

export default InitiativeSlice.reducer;
