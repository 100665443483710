import { styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { CONSTANTS } from "../../../data/constants";

export const CustomDataGrid = styled(DataGrid)(({ theme }) => {
  const currentYear = CONSTANTS?.currentYear;
  const createColumnsForYears = (year) => [
    `nsv_${year}`,
    `gp_${year}`,
    `gm_${year}`,
    `inc_gm_sector_${year}`,
    `inc_gm_total_${year}`,
  ];
  const dynamicStyles = {};
  const columnsStartingWithTotal = [
    "total_gm",
    "total_gm_sector",
    "total_gp",
    "total_nsv",
  ];

  const columnsForCurrentYear = createColumnsForYears(currentYear);
  const columnsForNextYear = createColumnsForYears(currentYear + 1);
  const columnsForYearAfterNext = createColumnsForYears(currentYear + 2);

  columnsStartingWithTotal.forEach((field) => {
    dynamicStyles[`& .MuiDataGrid-cell[data-field="${field}"]`] = {
      backgroundColor: "#C8BFE7",
    };
    dynamicStyles[`& .MuiDataGrid-columnHeader[data-field="${field}"]`] = {
      backgroundColor: "#C8BFE7",
    };
  });
  columnsForCurrentYear.forEach((field) => {
    dynamicStyles[`& .MuiDataGrid-cell[data-field="${field}"]`] = {
      backgroundColor: "#3FBAD91A",
    };
    dynamicStyles[`& .MuiDataGrid-columnHeader[data-field="${field}"]`] = {
      backgroundColor: "#3FBAD91A",
    };
  });
  columnsForNextYear.forEach((field) => {
    dynamicStyles[`& .MuiDataGrid-cell[data-field="${field}"]`] = {
      backgroundColor: "#E4D4C799",
    };
    dynamicStyles[`& .MuiDataGrid-columnHeader[data-field="${field}"]`] = {
      backgroundColor: "#E4D4C799",
    };
  });
  columnsForYearAfterNext.forEach((field) => {
    dynamicStyles[`& .MuiDataGrid-cell[data-field="${field}"]`] = {
      backgroundColor: "#5DB1341A",
    };
    dynamicStyles[`& .MuiDataGrid-columnHeader[data-field="${field}"]`] = {
      backgroundColor: "#5DB1341A",
    };
  });

  return {
    "& .MuiDataGrid-root": {},
    "& .MuiDataGrid-columnHeader--emptyGroup": {
      visibility: "hidden",
      border: "none",
    },
    "& .MuiDataGrid-columnHeader--filledGroup": {
      borderRadius: "10px 10px 0 0",
      fontWeight: 400,
    },
    '& .MuiDataGrid-columnHeader--filledGroup[data-fields*="|-total_nsv-|"]': {
      backgroundColor: "#C8BFE7",
      fontWeight: "bold",
    },
    [`& .MuiDataGrid-columnHeader--filledGroup[data-fields*="|-nsv_${currentYear}-|"]`]:
      {
        backgroundColor: "#3FBAD91A",
        fontWeight: "bold",
      },
    [`& .MuiDataGrid-columnHeader--filledGroup[data-fields*="|-nsv_${
      currentYear + 1
    }-|"]`]: {
      backgroundColor: "#E4D4C799",
    },
    [`& .MuiDataGrid-columnHeader--filledGroup[data-fields*="|-nsv_${
      currentYear + 2
    }-|"]`]: {
      backgroundColor: "#5DB1341A",
    },
    "& .MuiDataGrid-cell": {
      fontSize: "12px",
    },
    "& .MuiDataGrid-columnHeader": {
      backgroundColor: "#DFE4EB",
      "& .MuiDataGrid-iconButtonContainer": {
        visibility: "visible",
      },
      "& .MuiDataGrid-sortIcon": {
        opacity: 1,
        visibility: "visible",
      },

      "& .MuiDataGrid-columnHeaderTitle": {
        color: "#334d74",
        fontWeight: "bold",
        textAlign: "left",
      },
    },
    "& .MuiDataGrid-footerContainer ": {
      backgroundColor: "lightblue",
      fontWeight: "bold",
    },
    ...dynamicStyles,
  };
});
